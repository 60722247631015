// COLORS
$colors: (
	"p-light": $primary-light,
	"s-light": $secondary-light,
	"p-dark": $primary-dark,
	"s-dark": $secondary-dark,
	"accent": $accent,
	"success": $success,
);

@each $name, $color in $colors {
	.fill--#{$name} {
		fill: $color !important;
	}

	.color--#{$name} {
		color: $color !important;
	}

	.background--#{$name} {
		background: $color !important;
	}

	.border-color--#{$name} {
		border-color: $color !important;
	}
}

// TRANSITIONS
$transition-fast: 0.15s;
$transition-medium: 0.3s;
$transition-slow: 0.5s;

@mixin apply-transitions($duration) {
	transition: all $duration ease-in-out !important;
}

.transition-fast {
	@include apply-transitions($transition-fast);
}

.transition-medium {
	@include apply-transitions($transition-medium);
}

.transition-slow {
	@include apply-transitions($transition-slow);
}

.t-box-shadow {

	&--shallow:hover {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		z-index: 10;
	}

	&--deep:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		z-index: 10;
	}

}

.t-scale {
	&--small:hover {
		transform: scale(1.2);
	}

	&--medium:hover {
		transform: scale(1.1);
	}

	&--large:hover {
		transform: scale(1.02);
	}
}

@each $name, $color in $colors {

	.t-fill--#{$name}:hover {
		fill: $color !important;
	}

	.t-border-color--#{$name}:hover {
		border-color: $color !important;
	}

	.t-background--#{$name}:hover {
		background: $color !important;
	}

	.t-background-color--#{$name}:hover {
		background-color: $color !important;
	}

	.t-color--#{$name}:hover {
		color: $color !important;
	}

	.t-underline--#{$name}:hover {
		text-decoration: underline;
		text-decoration-color: $color !important;
	}
}

// HORIZONTAL LOOP
@keyframes slideAnimation {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-100%);
	}
}

.looper {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.looper__innerList {
	display: flex;
	justify-content: center;
	width: fit-content;
	will-change: transform;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
	animation: slideAnimation linear infinite;
}

.looper__listInstance {
	display: flex;
	width: max-content;
	animation: none;
}

.clickable {
	cursor: pointer;
}