//GLOBALS
body::-webkit-scrollbar {
	width: 0;
	height: 0;
}

body {
	color: $primary-dark;
	background-color: $primary-light;
}

::-webkit-scrollbar {
	width: 1rem;
	height: 1rem;
}

::-webkit-scrollbar-track {
	border-radius: 0.75rem;
}

::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 2rem;
	border: 0.25rem solid transparent;
	background-clip: padding-box;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

::-webkit-scrollbar-button {
	display: none;
}

::placeholder {
	font-style: italic;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-background-clip: text;
	-webkit-text-fill-color: $primary-dark;
	background-color: $primary-light;
	-webkit-box-shadow: 0 0 0 30px $primary-light inset !important;
	transition: background-color 5000s ease-in-out 0s;
}

.form-floating {
	label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100% !important;
	}
}

.form-label {
	letter-spacing: -0.05rem;
	padding: 0;
	margin: 0;
}

.form-text {
	letter-spacing: -0.02rem;
	padding: 0;
	margin: 0;
}

.input-group-text {
	background-color: transparent;
}

.form-check {
	label {
		margin-left: 0.75rem;
	}
}


//ATOMS
.material-symbols-outlined {
	border: none !important;
	font-variation-settings:
		'FILL' 0,
		'wght' 200,
		'GRAD' 0,
		'opsz' 24
}

.d-spinner {
	color: rgba($accent, 0.5);
}

.d-button {
	text-transform: uppercase;
	text-wrap: nowrap;

	display: flex;
	align-items: center;
	justify-content: center;

	&__type {

		&--link-light {
			background-color: transparent;
			border: $primary-light;
			color: $primary-light;

			&:hover,
			&:focus {
				color: darken($primary-light, 20%);
				text-decoration: underline;
			}
		}
	}
}


.d-form {

	[class*='col-'] {
		padding: 0;
	}

	.collapse>div,
	>* {
		padding: 0;
		margin: 0;
		margin-bottom: 1rem;
	}

	.collapse>div,
	>*:last-child {
		margin: 0;
	}

	textarea {
		overflow: hidden;
		resize: none;
	}

	.collapse>div,
	.input-group {
		padding: 0;
	}

	.collapse>div,
	.input-group.col {
		align-self: flex-start;
	}

}

.d-navbar {
	background: $primary-dark;

	.nav-link {
		color: $primary-light
	}
}

.invalidInput {
	border-color: $danger;
}

.blurred-pattern-background {
	height: 100vh;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background:
			linear-gradient(115deg, transparent 18.75%, $secondary-light 0 31.25%, transparent 0),
			repeating-linear-gradient(65deg, $secondary-light -6.25% 6.25%, $primary-light 0 18.75%);
		background-size: 500% 150%;
		filter: blur(2rem);
		z-index: -1;
	}
}

// CUSTOMIZED REACT DROPDOWN TREE SELECT
.tag {
	border: 1px solid #e9e9e9;
	padding: 2px 0 2px 2px;
	border-radius: 2px;
	display: inline-block
}

.tag-remove {
	color: #a0a0a0;
	font-size: 75%;
	line-height: 100%;
	cursor: pointer;
	background-color: transparent;
	border: none;
	outline: none
}

.tag-remove.disabled,
.tag-remove.readOnly {
	cursor: not-allowed
}

.tag-remove:focus {
	color: #3c3c3c
}

.node>label {
	cursor: pointer;
	margin-left: 2px;
}

.tag-list {
	display: none;
}

.tag-item {
	display: inline-block;
	margin: 4px
}

.tag-item .search {
	border: none;
	border-bottom: 1px solid #ccc;
	outline: none
}

.tag-item:last-child {
	margin-right: 4px
}

.node {
	list-style: none;
	white-space: nowrap;
	display: flex;
	align-items: center;
	overflow: hidden;
	padding: 2px 0px 2px 4px;

	label,
	.label {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.node.leaf.collapsed {
	display: none
}

.node.disabled>* {
	color: gray;
	cursor: not-allowed
}

.node.match-in-children.hide .node-label {
	opacity: .5
}

.toggle {
	white-space: pre;
	margin-right: 4px;
	font-size: 150%;
	line-height: 0;
	cursor: pointer
}

.toggle:after {
	content: " "
}

.toggle.collapsed:after {
	content: "+"
}

.toggle.expanded:after {
	content: "-"
}

.searchModeOn .toggle {
	display: none
}

.checkbox-item,
.radio-item {
	vertical-align: middle;
	margin: 0 4px 0 0
}

.checkbox-item.simple-select,
.radio-item.simple-select {
	display: none
}

.hide:not(.match-in-children) {
	display: none
}

.react-dropdown-tree-select .dropdown {
	position: relative;
	display: block;
	border: none;
	box-shadow: none;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
	display: none;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
	cursor: pointer
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
	content: "\25BC";
	vertical-align: middle;
	color: #3c3c3c;
	margin-right: 2px
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
	content: "\25B2";
	vertical-align: middle;
	color: #3c3c3c;
	margin-right: 2px
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
	cursor: not-allowed
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after {
	color: #b9b9b9
}

.react-dropdown-tree-select .dropdown .dropdown-content {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	border: none;
	box-shadow: none;
	padding: 0;
	margin: 0;
}

.react-dropdown-tree-select .dropdown .dropdown-content .search {
	width: 100%;
	border: none;
	border-bottom: 1px solid #ccc;
	outline: none
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
	margin: 0;
	padding: 0
}

//OLD STYLES
.searchContainer {

	//LAYOUT
	.row {
		margin: 0;
	}

	.searchCol {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.resultCol,
	.searchCol {
		padding: 0;
	}

	.searchFormFooter {
		display: flex;

		.list-group-item {
			flex-grow: 1;
		}
	}

	.results {
		overflow-y: auto;
	}

	//STYLING
	.nav-link {
		color: rgba($primary-dark, 0.8);
		fill: rgba($primary-dark, 0.8);
	}

	.nav-link.active {
		color: rgba($accent, 1) !important;
		fill: rgba($accent, 1);
	}

	.searchForm {
		border: none;

		.accordion-item {
			border: none;
			border-bottom: var(--bs-border-width) solid var(--bs-border-color);
		}

		.accordion-header {
			border: none;
		}

	}

	.searchFormFooter {
		text-align: center;
		font-style: italic;

		.list-group-item {
			border-radius: 0;
			border-bottom: none;
			border-left: none;

			&:last-child {
				border-radius: 0;
				border-right: none;
			}
		}
	}

	.resultCol,
	.searchCol {
		border: var(--bs-border-width) solid var(--bs-border-color);
		border-top: none;
	}

	.resultCol {
		border-left: none;
	}

	.formContainer {
		border: var(--bs-border-width) solid var(--bs-border-color);

		padding-bottom: 0.5rem;
		padding-top: 0.5rem;

		overflow-y: auto;

		textarea {
			overflow: hidden;
			resize: none;
		}

		.row {
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
		}
	}
}

.inputFieldButton {
	border: var(--bs-border-width) solid var(--bs-border-color);
}