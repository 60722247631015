$primary-dark: #373E41;
$primary-light: #fcfcfc;
$secondary-dark: #464d50;
$secondary-light: #f7f7f7;
$accent: #e4653d;
$success: #3de46c;

:root {
	--primary-dark: #{$primary-dark};
	--primary-light: #{$primary-light};
	--secondary-dark: #{$secondary-dark};
	--secondary-light: #{$secondary-light};
	--accent: #{$accent};
	--success: #{$success};
}

$theme-colors: (
	"primary": $primary-dark,
	"secondary": $primary-light,
	"success": $success,
	"danger": #f44336,
	"warning": #ff850a,
	"info": $accent,
	"light": #f4f4f4,
	"dark": #333333
);

//GLOBAL COMPONENT VARIABLES
$accordion-button-active-bg: none;
$accordion-button-focus-border-color: none;
$body-bg: $primary-light;
$card-bg: none;
$card-border-color: none;
$card-cap-bg: none;
$component-active-bg: $accent;
$dropdown-link-active-bg: none;
$dropdown-link-hover-bg: none;
$dropdown-link-hover-color: $accent;
$focus-ring-box-shadow: none;
$font-family-base: 'Space Mono', monospace;
$form-check-input-focus-border: none;
$form-select-focus-box-shadow: none;
$form-label-margin-bottom: none;
$input-focus-border-color: $accent;
$link-color: $accent;
$hr-border-color: rgba($secondary-dark, 0.5);
$container-padding-x: 0;
$form-check-padding-start: 0;