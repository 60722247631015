@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pavanam:wght@400&display=swap');

html {
	font-size: 16px;
}

@media (max-width: 1199.98px) {
	html {
		font-size: 14.5px;
	}
}

@media (max-width: 991.98px) {
	html {
		font-size: 13px;
	}
}

@media (max-width: 767.98px) {
	html {
		font-size: 11.5px;
	}
}

@media (max-width: 575.98px) {
	html {
		font-size: 10px;
	}
}

h1 {
	font-size: 2.986rem;
}

h2 {
	font-size: 2.488rem;
}

h3 {
	font-size: 2.074rem;
}

h4 {
	font-size: 1.728rem;
}

h5 {
	font-size: 1.44rem;
}

h6 {
	font-size: 1.2rem;
}

body,
p {
	font-size: 1rem;
}

small {
	font-size: 0.833rem;
}

@mixin heading-font {
	font-family: 'Space Mono', monospace;
	font-weight: 400;
	margin: 0;
	margin-bottom: 0.3rem;
}

@mixin subheading-font {
	font-family: 'Fira Sans Extra Condensed', sans-serif;
	font-weight: 200;
	margin: 0;
	margin-bottom: 0.2rem;
}

@mixin body-font {
	font-family: 'Pavanam', sans-serif;
	font-weight: 400;
	margin: 0;
}

@mixin body-font-bold {
	font-family: 'Pavanam', sans-serif;
	font-weight: 600;
	letter-spacing: 0.07rem;
	margin: 0;
}


.d-text {
	text-shadow: 0.07rem 0.07rem 0.07rem rgba(0, 0, 0, 0.1);

	&__font {
		&--heading {
			@include heading-font;
		}

		&--subheading {
			@include subheading-font;
		}

		&--body {
			@include body-font;
		}

		&--body-bold {
			@include body-font-bold;
		}
	}

	&__placement {
		&--center {
			margin: 0;
			padding: 0;
			text-align: center;
		}
	}
}